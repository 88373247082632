/* @font-face {
  font-family: Akkurat;
  src: url(../src/fonts/Akkurat.ttf);
} */

/* eot --- IE6–8 */
/* woff',  Firefox 3.6+, IE9, Chrome 6+, Safari 5.1+ */
/* ttf ,  Safari 3—5, Chrome4+, Firefox 3.5, Opera 10+ */

@font-face {
  font-family: "Telstra Akkurat";
  src: url(../src/fonts/Akkurat.ttf) format("truetype"),
    url(../src/fonts/Akkurat.woff) format("woff"),
    url(../src/fonts/Akkurat.eot) format("eot");
}
@font-face {
  font-family: "Helvetica Neue";
  src: url(../src/fonts/HelveticaNeue.ttf);
}
@font-face {
  font-family: "Arial Regular";
  src: url(../src/fonts/Arialregular.ttf) format("truetype"),
    url(../src/fonts/Arial.woff) format("woff"),
    url(../src/fonts/Arial.eot) format("eot");
}
/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("../src/fonts/Montserrat-Bold.eot") format("eot"),
    url("../src/fonts/Montserrat-Bold.ttf") format("truetype"),
    url("../src/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("../src/fonts/Montserrat-Bold.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("../src/fonts/Montserrat-SemiBold.eot") format("eot"),
    url("../src/fonts/Montserrat-SemiBold.ttf") format("truetype"),
    url("../src/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("../src/fonts/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("../src/fonts/Montserrat-Regular.eot") format("eot"),
    url("../src/fonts/Montserrat-Regular.ttf") format("truetype"),
    url("../src/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("../src/fonts/Montserrat-Regular.woff") format("woff");
}

body {
  margin: 0;
  font: 0.875rem/1.714 Helvetica Neue, Helvetica, Arial, sans-serif;
  font-family: Telstra Akkurat, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-background-color: "#fff";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.t-loader__spinner-container {
  content: "";
  display: block;
  padding-top: 100%;
}

.t-circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 48px;
  height: 49px;
  margin: auto;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 360px;
  background-color: transparent;
  box-shadow: 3px 4px 5px 0 rgba(138, 130, 138, 1);
  color: #000;
  text-align: center;
  border-radius: 0;
  padding: 25px;
  height: auto;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 162%;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.t-input-helper:hover tooltiptext {
  visibility: visible;
}
.tooltipheader {
  background-image: linear-gradient(
    90deg,
    #00c0f3 0,
    #bd8cbf 60.56%,
    #5f84c2 100%
  );
  display: block;
  width: 115%;
  height: 0.125rem;
  margin-top: -24px;
  margin-left: -22px;
  display: block;
}
.tooltext {
  color: #414141 !important;
  font-family: "Telstra Akkurat Regular" !important;
  font-size: 14px;
  margin-top: 36px;
  text-align: left;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .tooltipmob,
  .tooltip .tooltiptext {
    width: 300px !important;
    margin: auto;
    left: -270px !important;
    height: auto !important;
    right: 0 !important;
    float: left;
  }
  .tooltipmob button {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 400px !important;
    background-color: #fff !important;
    box-shadow: 3px 4px 5px 0 rgba(138, 130, 138, 1);
    color: #000;
    text-align: center;
    border-radius: 0;
    padding: 25px;
    height: 345px !important;
    position: absolute !important;
    z-index: 1000000 !important;
    top: 25px !important;
    left: -1630% !important;
  }
  .tooltipmob,
  .tooltip .tooltiptext {
    width: 322px !important;
    margin: auto;
    left: -322px !important;
    height: auto !important;
    right: 0 !important;
    float: left;
  }
  .tooltipmob button {
    width: 100% !important;
  }
}
.custom-button-width {
  width: 100% !important;
}
@media screen and (max-width: 1023px) {
  .t-able-spacing-v-mt-half {
    margin-top: 0.25rem;
  }
  .t-able-spacing-v-mt-1x {
    margin-top: 0.5rem;
  }
  .t-able-spacing-v-mt-2x {
    margin-top: 1rem;
  }
  .t-able-spacing-v-mt-3x {
    margin-top: 1.5rem;
  }
  .t-able-spacing-v-mt-4x {
    margin-top: 2rem;
  }
  .t-able-spacing-v-mt-5x {
    margin-top: 2.5rem;
  }
  .t-able-spacing-v-mt-7x {
    margin-top: 3.5rem;
  }
  .t-able-spacing-v-mt-10x {
    margin-top: 5rem;
  }
  .t-able-spacing-v-pb-half {
    padding-bottom: 0.25rem;
  }
  .t-able-spacing-v-pb-1x {
    padding-bottom: 0.5rem;
  }
  .t-able-spacing-v-pb-2x {
    padding-bottom: 1rem;
  }
  .t-able-spacing-v-pb-3x {
    padding-bottom: 1.5rem;
  }
  .t-able-spacing-v-pb-4x {
    padding-bottom: 2rem;
  }
  .t-able-spacing-v-pb-5x {
    padding-bottom: 2.5rem;
  }
  .t-able-spacing-v-pb-7x {
    padding-bottom: 3.5rem;
  }
  .t-able-spacing-v-pb-10x {
    padding-bottom: 5rem;
  }
  .t-able-spacing-v-mb-half {
    margin-bottom: 0.25rem;
  }
  .t-able-spacing-v-mb-1x {
    margin-bottom: 0.5rem;
  }
  .t-able-spacing-v-mb-2x {
    margin-bottom: 1rem;
  }
  .t-able-spacing-v-mb-3x {
    margin-bottom: 1.5rem;
  }
  .t-able-spacing-v-mb-4x {
    margin-bottom: 2rem;
  }
  .t-able-spacing-v-mb-5x {
    margin-bottom: 2.5rem;
  }
  .t-able-spacing-v-mb-7x {
    margin-bottom: 3.5rem;
  }
  .t-able-spacing-v-mb-10x {
    margin-bottom: 5rem;
  }
}
@media screen and (min-width: 1024px) {
  .t-able-spacing-v-mt-half {
    margin-top: 0.5rem;
  }
  .t-able-spacing-v-mt-1x {
    margin-top: 1rem;
  }
  .t-able-spacing-v-mt-2x {
    margin-top: 1rem;
  }
  .t-able-spacing-v-mt-3x {
    margin-top: 1.5rem;
  }
  .t-able-spacing-v-mt-4x {
    margin-top: 2.5rem;
  }
  .t-able-spacing-v-mt-5x {
    margin-top: 3.5rem;
  }
  .t-able-spacing-v-mt-7x {
    margin-top: 5rem;
  }
  .t-able-spacing-v-mt-10x {
    margin-top: 7.5rem;
  }
  .t-able-spacing-v-pb-half {
    padding-bottom: 0.5rem;
  }
  .t-able-spacing-v-pb-1x {
    padding-bottom: 1rem;
  }
  .t-able-spacing-v-pb-2x {
    padding-bottom: 1rem;
  }
  .t-able-spacing-v-pb-3x {
    padding-bottom: 1.5rem;
  }
  .t-able-spacing-v-pb-4x {
    padding-bottom: 2.5rem;
  }
  .t-able-spacing-v-pb-5x {
    padding-bottom: 3.5rem;
  }
  .t-able-spacing-v-pb-7x {
    padding-bottom: 5rem;
  }
  .t-able-spacing-v-pb-10x {
    padding-bottom: 7.5rem;
  }
  .t-able-spacing-v-mb-half {
    margin-bottom: 0.5rem;
  }
  .t-able-spacing-v-mb-1x {
    margin-bottom: 1rem;
  }
  .t-able-spacing-v-mb-2x {
    margin-bottom: 1rem;
  }
  .t-able-spacing-v-mb-3x {
    margin-bottom: 1.5rem;
  }
  .t-able-spacing-v-mb-4x {
    margin-bottom: 2.5rem;
  }
  .t-able-spacing-v-mb-5x {
    margin-bottom: 3.5rem;
  }
  .t-able-spacing-v-mb-7x {
    margin-bottom: 5rem;
  }
  .t-able-spacing-v-mb-10x {
    margin-bottom: 7.5rem;
  }
}

.footer-content-details {
  margin: 0px;
  font-size: 12px;
}
.t-form-container,
.t-message-container,
.t-splash-container {
  min-height: 80vh;
  overflow: hidden;
}
.t-form-container {
  max-width: 376px;
  margin: auto;
  display: block;
  position: relative;
  padding: 0 8px 62px;
}
.t-form-container h1:focus,
.t-message-container h1:focus {
  outline: 0;
}
@media screen and (max-width: 768px) {
  .t-form-container {
    max-width: 321px;
    margin: auto;
    padding-top: 3.5rem;
  }
}
.center-alignment {
  display: flex;
  justify-content: center;
}
.field_error_txt {
  padding: 0rem 0 0rem 1.875rem;
  color: #de2504 !important;
  font: 400 0.875rem/1.4 Telstra Akkurat, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
  line-height: 20px;
}
#recaptcha__error-text {
  position: relative;
}
.field_error_txt::before,
.field_error_txt::before,
.field_error_txt::before {
  top: -2px !important;
  content: "";
  position: absolute;
  display: inline-block;
  background-image: url(https://www.telstra.com.au/content/dam/tcom/able/Error.svg);
  width: 1.5rem;
  height: 1.5rem;
  left: 0px;
}
.logo-bar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 18px 40px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  vertical-align: middle;
}
h1:focus span,
h1:active span,
h1:focus-visible span,
h1 span {
  outline: none !important;
}

.loggedin-agent-action {
  display: flex;
  justify-content: space-between;
}
.loggedin-agent-action > button,
.agent-action > button {
  padding: 0px;
  margin-left: 10px;
}
.passkey-action  > button {
  padding: 0px;
  margin-left: 25px;
}
.agent-action , .passkey-action, .passkey-modal-actions{
  display: flex;
  justify-content: flex-end;
}
.passkey-modal-actions button{
  flex-basis: 30% !important;
  margin-left: 5%;
}
@media (max-width: 680px) {
  .loggedin-agent-action,
  .agent-action {
    flex-direction: column;
  }
  .loggedin-agent-action > button, .agent-action  > button, .passkey-action > button {
    padding: 0px;
    padding-top: 20px;
  }
  
}
.custom-witdth-lozenge {
  width: fit-content;
}
.idleConfirmationDialog .able-ActionButton--medium-emphasis {
  display: none !important;
}

.idleConfirmationDialog .able-Dialog--Confirmation__actions > *:last-child {
  margin-left: 0 !important;
}
.displayFlex {
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  margin-bottom: 10px;
  font: 400 0.875rem/1.4 Telstra Akkurat, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}
.errorIcon {
  padding-right: 5px;
}
.errorText {
  color: #0064d2;
}
.pl-10 {
  padding-left: 10px;
}

.signinLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  z-index: 9;
  overflow: hidden;
}

.spinner-box {
  width: 100%;
  height: 125px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.t-text-center {
  text-align: center;
}
.bold-font{
  font-weight: bolder;
  font-family: 'Telstra Akkurat'
}
